module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"53aa06cf17e4239d0dba6ffd09854e02"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"default":"../components/layout.tsx"},"rehypePlugins":[null],"gatsbyRemarkPlugins":[{"resolve":"/github/workspace/node_modules/gatsby-remark-behead","id":"044ab05d-404a-5630-b899-5fdfbde65cca","name":"gatsby-remark-behead","version":"0.0.3","modulePath":"/github/workspace/node_modules/gatsby-remark-behead/index.js","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]}],"extensions":[".mdx"],"lessBabel":false,"remarkPlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/github/workspace","commonmark":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
